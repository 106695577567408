import React from "react";
import { Layout, centerInGrid } from "components";
import { SEO } from "utils";
import clsx from "clsx";
import { Link } from "gatsby";
import { ArrowBackSharp } from "@material-ui/icons";

const KontaktErfolg = () => {
  return (
    <Layout>
      <SEO title="Kontakt Erfolg" />
      <div
        className={clsx("flex justify-center flex-col max-w-5xl", centerInGrid)}
      >
        <h2 className="text-4xl my-3">Vielen Dank für Ihre Nachricht!</h2>
        <p className="text-xl mb-4 border-b-4 border-orange-200 max-w-max pb-4">
          Wir melden uns schnellstmöglich bei Ihnen.
        </p>
        <Link
          to="/"
          className="text-blue-800 hover:text-blue-500 flex items-center"
        >
          <ArrowBackSharp className="mr-2" /> Zurück zur Homepage
        </Link>
      </div>
    </Layout>
  );
};

export default KontaktErfolg;
